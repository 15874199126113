import { j as a } from "./jsx-runtime-D0AQ1Mn7.js";
import { c as e } from "./utils-CJ9afRe1.js";
import "./Accordion-DcmbANja.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-8pjW7QUn.js";
import "./Icon-vWfhOtJ0.js";
import "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as n } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import { S as p } from "./Stack-B4QZt_R0.js";
import "./Switch-DSz0C0fA.js";
import "./Toast-CLY2YdmW.js";
import "react";
import "./Toggle-D-drA526.js";
const o = ({ children: r, className: t, col: i }) => /* @__PURE__ */ a.jsx(
  "li",
  {
    className: e(
      "border-b border-gray-card-border last:border-0 mt-8 first:mt-0 pb-1",
      t
    ),
    children: /* @__PURE__ */ a.jsx(p, { row: !i, className: "justify-between", children: r })
  }
);
o.displayName = "LoanCardItem";
const m = ({ children: r, className: t }) => /* @__PURE__ */ a.jsx(n, { size: "sm", className: e("text-gray-neutral-70", t), children: r });
m.displayName = "LoanCardItemTitle";
const s = ({ children: r, className: t }) => /* @__PURE__ */ a.jsx("div", { className: e(t), children: r });
s.displayName = "LoanCardItemValue";
const d = ({ className: r, children: t }) => /* @__PURE__ */ a.jsx("ul", { className: e("bg-white-neutral rounded-md p-4", r), children: t }), S = Object.assign(d, {
  Item: o,
  Title: m,
  Value: s
});
export {
  S as L
};
